import { useState } from 'react';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import { Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { backendRoutes, errorMessage, frontendRoutes } from '../../../Constants/constants';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import { useDispatch } from 'react-redux';
import { getPhoneNumber } from '../../../redux/action/action';
import { phValidationSchema } from '../../../Constants/validations';
import { setTimeout } from 'timers';
import { setToLogin, isUserTokenExpired } from '../../../utils';
import InputMask from 'react-input-mask';

const SignInForm = () => {
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const previousPath = () => {
    history.goBack();
  };
  let params = useParams<{ builder_name?: string }>();

  const token = localStorage.getItem('static_token');
  const user_token = localStorage.getItem('user_token');
  const phone_verify = (formData: any) => {
    setIsLoading(true);
    axios({
      baseURL: backendRoutes.MOBILE_BASE_URL,
      url: backendRoutes.verify_phone_number,
      method: 'post',
      headers: {
        'x-auth-token': token,
      },
      data: formData,
    })
      .then(res => {
        if (res.status === 201) {
          setIsLoading(false);
          history.push(frontendRoutes.howItWorks(params.builder_name).path);
        }
      })
      .catch(err => {
        if (err && err.response && err.response.status === 403) {
          setIsLoading(false);
          if (user_token && !isUserTokenExpired(user_token)) {
            history.push(frontendRoutes.preferTour(params.builder_name).path);
          } else {
            setToLogin();
            history.push(frontendRoutes.emailVerify(params.builder_name).path);
          }
        } else {
          if (formik.values.phoneNumber === '') {
            setIsLoading(false);
            setShowAlert(true);
            setMessage(errorMessage.signMessage);
          } else {
            setIsLoading(false);
            setShowAlert(true);
            setMessage('Please check your connection, Network Error !!!');
            setTimeout(() => {
              setShowAlert(false);
            }, 1000);
          }
        }
      });
  };
  const gotoRegister = () => {
    dispatch(getPhoneNumber(''));
    history.push(frontendRoutes.howItWorks(params.builder_name).path);
  };
  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    validationSchema: phValidationSchema,
    onSubmit: values => {
      const data = {
        phone: values.phoneNumber.replace(/\D/g, ''),
      };
      phone_verify(data);
      dispatch(getPhoneNumber(data));
    },
  });

  return (
    <section className="main-section signup-screen d-flex justify-content-center">
      <div className="box">
        <Header />
        <span onClick={previousPath} className="link-back icn icn-back-arrow"></span>
        <div className="box-data d-flex flex-column">
          <Form onSubmit={formik.handleSubmit} className="h-100 d-flex flex-column">
            <FormikProvider value={formik}>
              <Form.Group controlId="formBasicEmail">
                {showAlert === false ? (
                  <Form.Label>Sign in with your phone number.</Form.Label>
                ) : (
                  <Form.Label>{message}</Form.Label>
                )}
                <InputMask
                  mask="999-999-9999"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                >
                  {() => (
                    <Form.Control
                      type="text"
                      placeholder="000-000-0000"
                      name="phoneNumber"
                      isInvalid={!!formik.errors.phoneNumber}
                      className={showAlert ? 'is-invalid' : ''}
                    />
                  )}
                </InputMask>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.phoneNumber}
                </Form.Control.Feedback>
              </Form.Group>
            </FormikProvider>
            <div className="devider">
              <span>OR</span>
            </div>
            <p className="mb-0">
              Need an account with NterNow?{' '}
              <span className="a" onClick={gotoRegister}>
                Register here
              </span>
              .
            </p>

            <div className="box-footer mt-auto">
              <small>
                I understand that during the COVID-19 crisis, I am touring at my own risk and will
                ensure to follow the 5 CDC recommended guidelines when conducting a self-tour. For
                more information:{' '}
                <a href="https://nternow.com/" rel="noreferrer" target="_blank">
                  COVID-19 policy
                </a>
                .
              </small>
              {isLoading && <Loader />}
              <button type="submit" className="button w-100">
                Continue
              </button>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
      <a href="https://nternow.com/" rel="noreferrer" className="help-link" target="_blank">
        Need Help?
      </a>
    </section>
  );
};

export default SignInForm;
