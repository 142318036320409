import Footer from '../../Footer/Footer'
import { Link, useHistory, useParams } from 'react-router-dom';
import { frontendRoutes } from "../../../Constants/constants";
import Loader from '../../Loader/Loader';
import { useState } from 'react';
import { setTimeout } from 'timers';

const ShareLocation = () => {
	const history = useHistory();
	const previousPath = () => {
		history.goBack();
	}
	let params = useParams<{ builder_name?: string }>();
	const [isLoading, setIsLoading] = useState(false)
	const onAccept = (event: any) => {
		event.preventDefault();
		navigator.geolocation.getCurrentPosition(function (position) {
			setIsLoading(true);
			// console.log("Latitude is :", position.coords.latitude);
			// console.log("Longitude is :", position.coords.longitude);
			setTimeout(() => {
				setIsLoading(false)
				history.push(frontendRoutes.LockCodeScreen(params.builder_name).path)
			}, 1000)
		});
	}

	return (
		<section className="main-section location-screen d-flex justify-content-center">
			<div className="box">
				<div className="main-title">
				</div>
				<span onClick={previousPath} className="link-back icn icn-back-arrow"></span>

				<div className="box-data d-flex flex-column text-center">
					<i className="icn icn-pin"></i>
					<h1 className="h2">Please share your location</h1>
					<p>In order to continue and use this app, we will need you to agree to share your location to verify you are in front of the property.</p>
					{isLoading && <Loader />}
					<div className="box-footer mt-auto">
						<Link onClick={onAccept} to={frontendRoutes.LockCodeScreen(params.builder_name).path} className="button w-100">Continue</Link>
					</div>
				</div>
				<Footer />
			</div>
		</section>
	)
}

export default ShareLocation
