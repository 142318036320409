import { TOUR_DATE_FAIL, TOUR_DATE_REQUEST, TOUR_DATE_SUCCESS } from "../types/actionTypes";

export const tourDateReducer = (state={}, action:any) => {
    switch (action.type) {
        case TOUR_DATE_REQUEST:
          return { ...state, loading: true };
        case TOUR_DATE_SUCCESS:
          return { loading: false, tour: action.payload };
        case TOUR_DATE_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;
      }
}
