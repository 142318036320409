import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import Footer from '../../Footer/Footer';
import { frontendRoutes } from '../../../Constants/constants';
import { selectBuilderSettings } from '../../../redux/selectors/builders';
import defaultLogo from '../../../assets/images/default-logo.svg';

const TourPrefereces = () => {
  const history = useHistory();
  let params = useParams<{ builder_name?: string }>();
  const previousPath = () => {
    history.goBack();
  };

  const builderSettings = useSelector(selectBuilderSettings);

  return (
    <section className="main-section tour-screen d-flex justify-content-center">
      <div className="box">
        <div className="main-title">
          <figure>
            <img src={builderSettings.logo || defaultLogo} alt="Fairfield" />
          </figure>
          <span className="text-blue">
            <sup>*</sup>required
          </span>
        </div>
        <span onClick={previousPath} className="link-back icn icn-back-arrow"></span>
        <div className="box-data d-flex flex-column">
          <h1>How do you want to tour?</h1>
          <div className="link-item">
            <ul>
              <li>
                <Link
                  to={frontendRoutes.confirmYourself(params.builder_name).path}
                  className="icn icn-back-arrow"
                ></Link>
                <i className="icn icn-person"></i>
                <h2>In Person Tour</h2>
                <small>Schedule in person self tour!</small>
              </li>
            </ul>
          </div>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default TourPrefereces;
