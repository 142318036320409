export const REGISTER_USER_REQUEST="REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS="REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL="REGISTER_USER_FAIL";

export const USER_DETAIL_REQUEST = "USER_DETAIL_REQUEST";
export const USER_DETAIL_SUCCESS = "USER_DETAIL_SUCCESS";
export const USER_DETAIL_FAIL = "USER_DETAIL_FAIL";

export const BUILDER_SETTINGS_REQUEST = "BUILDER_SETTINGS_REQUEST";
export const BUILDER_SETTINGS_SUCCESS = "BUILDER_SETTINGS_SUCCESS";
export const BUILDER_SETTINGS_FAIL = "BUILDER_SETTINGS_FAIL";

export const PHONE_NUMBER_REQUEST="PHONE_NUMBER_REQUEST";
export const PHONE_NUMBER_SUCCESS="PHONE_NUMBER_SUCCESS";
export const PHONE_NUMBER_FAIL="PHONE_NUMBER_FAIL";

export const PERSONA_DATA_REQUEST="PERSONA_DATA_REQUEST";
export const PERSONA_DATA_SUCCESS="PERSONA_DATA_SUCCESS";
export const PERSONA_DATA_FAIL="PERSONA_DATA_FAIL";

export const CHOOSE_COMMUNITY_REQUEST="CHOOSE_COMMUNITY_REQUEST";
export const CHOOSE_COMMUNITY_SUCCESS="CHOOSE_COMMUNITY_SUCCESS";
export const CHOOSE_COMMUNITY_FAIL="CHOOSE_COMMUNITY_FAIL";

export const SEARCH_COMMUNITY_REQUEST="SEARCH_COMMUNITY_REQUEST";
export const SEARCH_COMMUNITY_SUCCESS="SEARCH_COMMUNITY_SUCCESS";
export const SEARCH_COMMUNITY_FAIL="SEARCH_COMMUNITY_FAIL";

export const GET_PROPERTY_REQUEST="GET_PROPERTY_REQUEST";
export const GET_PROPERTY_SUCCESS="GET_PROPERTY_SUCCESS";
export const GET_PROPERTY_FAIL="GET_PROPERTY_FAIL";

export const TOUR_DATE_REQUEST="TOUR_DATE_REQUEST";
export const TOUR_DATE_SUCCESS="TOUR_DATE_SUCCESS";
export const TOUR_DATE_FAIL="TOUR_DATE_FAIL";