import axios from 'axios';
import { backendRoutes } from './constants';

export const static_token = () => {
  axios({
    baseURL: backendRoutes.MOBILE_BASE_URL,
    url: backendRoutes.static_token,
    method: 'get',
  })
    .then((result: any) => {
      const token = result.data.token;
      localStorage.setItem('static_token', token);
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const message_token = () => {
  axios({
    baseURL: backendRoutes.MOBILE_BASE_URL,
    url: backendRoutes.message_token,
    method: 'get',
  })
    .then((result: any) => {
      const message_token = result.data.messagingToken;
      localStorage.setItem('message_token', message_token);
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const getBuilderId = (builder_name: any) => {
  axios({
    baseURL: backendRoutes.MOBILE_BASE_URL,
    url: `builders/name/${builder_name.builder_name}`,
    method: 'GET',
  })
    .then((result: any) => {
      let builder_id = result.data.builderId;
      localStorage.setItem('builder_id', builder_id);
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const getBuilderSettings = async (builderId: string | null) => {
  try {
    return axios({
      baseURL: backendRoutes.MOBILE_BASE_URL,
      url: `builders/settings/${builderId}`,
      method: 'GET',
    });
  } catch (error) {
    console.log(error);
  }
};
