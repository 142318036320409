import { GET_PROPERTY_FAIL, GET_PROPERTY_REQUEST, GET_PROPERTY_SUCCESS } from "../types/actionTypes";

export const getPropertyReducer = (state={}, action:any) => {
    switch (action.type) {
        case GET_PROPERTY_REQUEST:
          return { ...state, loading: true };
        case GET_PROPERTY_SUCCESS:
          return { loading: false, property: action.payload };
        case GET_PROPERTY_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;
      }
}
