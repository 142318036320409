import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect, useParams } from 'react-router-dom';
// import logo from './logo.svg';
import './App.css';
import ChooseCommunity from './Components/LandingPage/Community/ChooseCommunity';
import { CommunityScreen } from './Components/LandingPage/Community/communityScreen';
import SearchCommunity from './Components/LandingPage/Community/SearchCommunity';
import documentVerify from './Components/LandingPage/Document-Verification/documentVerify';
import EmailVerify from './Components/LandingPage/EmailVerification/EmailVerify';
import HowitWork from './Components/LandingPage/How-it-works/HowitWork';
import IsThisYou from './Components/LandingPage/Is-this-you/IsThisYou';
import OtpVerification from './Components/LandingPage/OtpVerification/OtpVerification';
import PhoneVerification from './Components/LandingPage/PhoneVerification/PhoneVerification';
import ShareLocation from './Components/LandingPage/ShareLocation/ShareLocation';
import SignInForm from './Components/LandingPage/SigninForm/SignupForm';
// import SuccessPage from './Components/LandingPage/SuccessPage/SuccessPage';
import ConfirmScreen from './Components/LandingPage/SuccessPage/TourSuccess/ConfirmScreen';
import LockScreen from './Components/LandingPage/SuccessPage/TourSuccess/LockScreen';
import TextVerify from './Components/LandingPage/Text-Verification/TextVerify';
import SelectTourDate from './Components/LandingPage/TourPreference/SelectTourDate';
import TourHome from './Components/LandingPage/TourPreference/TourHome';
import TourPrefereces from './Components/LandingPage/TourPreference/TourPrefereces';
import { frontendRoutes } from './Constants/constants';
import { getBuilderSettings } from './redux/action/action';
import './style.scss';
import { isUserToken } from './utils';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  let params = useParams<{ builder_name?: string }>();
  return (
    <Route
      {...rest}
      render={props =>
        isUserToken() ? (
          <Component {...props} />
        ) : (
          <Redirect to={frontendRoutes.emailVerify(params.builder_name).path} />
        )
      }
    />
  );
};

const App = (props: any) => {
  const dispatch = useDispatch();

  dispatch(getBuilderSettings());

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/:builder_name" component={CommunityScreen} />
          <Route exact path={frontendRoutes.community().path} component={CommunityScreen} />
          <Route exact path={frontendRoutes.signIn().path} component={SignInForm} />
          <Route exact path={frontendRoutes.howItWorks().path} component={HowitWork} />

          {/* Messaging Token */}
          <Route exact path={frontendRoutes.phoneVerify().path} component={PhoneVerification} />
          <Route exact path={frontendRoutes.otpVerify().path} component={OtpVerification} />
          <Route exact path={frontendRoutes.documentVerify().path} component={documentVerify} />
          <Route exact path={frontendRoutes.textVerify().path} component={TextVerify} />

          {/* <Route exact path={frontendRoutes.success().path} component={SuccessPage} /> */}
          <Route exact path={frontendRoutes.isThisYou().path} component={IsThisYou} />
          <Route exact path={frontendRoutes.emailVerify().path} component={EmailVerify} />

          {/* User required Token */}
          <Route exact path={frontendRoutes.chooseCommunity().path} component={ChooseCommunity} />
          <Route exact path={frontendRoutes.searchCommunity().path} component={SearchCommunity} />
          <PrivateRoute exact path={frontendRoutes.preferTour().path} component={TourPrefereces} />
          <PrivateRoute exact path={frontendRoutes.confirmYourself().path} component={TourHome} />
          <PrivateRoute
            exact
            path={frontendRoutes.selectTourDate().path}
            component={SelectTourDate}
          />
          <PrivateRoute
            exact
            path={frontendRoutes.shareLocation().path}
            component={ShareLocation}
          />
          <PrivateRoute exact path={frontendRoutes.LockCodeScreen().path} component={LockScreen} />
          <PrivateRoute
            exact
            path={frontendRoutes.ConfirmTourScreen().path}
            component={ConfirmScreen}
          />
        </Switch>
      </Router>
    </>
  );
};

export default App;
