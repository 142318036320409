import { Spinner } from "react-bootstrap"

const Loader = () => {
    return (
        <div className="ajax-loader">
            <Spinner animation="border" role="status">
            </Spinner>
        </div>
    )
}

export default Loader
