import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom"
import { checkOS, frontendRoutes } from "../../../../Constants/constants";

const ConfirmScreen = () => {
    const history = useHistory();
    const previousPath = () => {
        history.goBack();
    }
    let params = useParams<{ builder_name?: string }>();
    const tourDate = useSelector((state: any) => state.tour.tour)

    return (
        <section className="main-section success-screen tour-success-screen d-flex justify-content-center">
            <div className="box">
                <div className="success-title">
                    <h1>SUCCESS!</h1>
                    <span>Your tour is successfully scheduled: {tourDate && tourDate.showDate}, at {tourDate && tourDate.customTime} {tourDate && tourDate.choose.label}</span>
                    <div className="d-flex w-100">
                        <span onClick={previousPath} className="a">Cancel</span>
                        <Link to={frontendRoutes.selectTourDate(params.builder_name).path} className="">Reschedule</Link>
                    </div>
                </div>
                <div className="box-data d-flex flex-column text-center">
                    <div className="my-auto text-center">
                        <i className="icn icn-home"></i>
                        <p>You will receive a confirmation email with your scheduled tour.</p>
                    </div>
                    <div className="box-footer">
                        <button onClick={checkOS} className="button w-100">Download NterNow</button>
                        <p>Please download the app to continue browsing properties</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ConfirmScreen
