import { useEffect, useState } from 'react';

import Footer from '../../Footer/Footer';

import { Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import search_image from '../../../assets/images/search-image.jpg';
import { backendRoutes, frontendRoutes } from '../../../Constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import { get_property_details } from '../../../redux/action/action';

const SearchCommunity = () => {
  const history = useHistory();
  const previousPath = () => {
    history.goBack();
  };
  let params = useParams<{ builder_name?: string }>();

  const [isLoading, setIsLoading] = useState(false);
  const [propertyList, setPropertyList] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [filterData, setFilterData] = useState<any[]>([]);
  const token = localStorage.getItem('static_token');
  const dispatch = useDispatch();
  const community = useSelector((state: any) => state.community.community);
  const searchCommunity = useSelector((state: any) => state.searchCommunity.search);

  useEffect(() => {
    const property =
      community && community.filter((data: any) => data.id === searchCommunity.choose.value);

    const get_properties = (id: any) => {
      setIsLoading(true);
      axios({
        baseURL: backendRoutes.MOBILE_BASE_URL,
        url: `properties/${id}/info`,
        method: 'GET',
        headers: {
          'x-auth-token': token,
        },
      })
        .then(res => {
          setIsLoading(false);
          setPropertyList(arr => [...arr, { id, ...res.data }]);
        })
        .catch(err => {
          console.log(err);
        });
    };

    property &&
      property[0].community.properties.map((id: any) => {
        return get_properties(id);
      });
  }, [community, searchCommunity.choose.value, token]);

  const excludeColumns = ['price', 'longitude', 'latitude', 'beds', 'baths', 'sqft'];
  const searchItems = (searchValue: any) => {
    setSearchInput(searchValue);
    const lowercasedValue = searchInput.toLowerCase().trim() || searchInput;
    if (lowercasedValue === '') setFilterData(propertyList);
    else {
      const filteredData = propertyList.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue),
        );
      });
      setFilterData(filteredData);
    }
  };
  const getPropertyValue = (data: any) => {
    dispatch(get_property_details(data));
    history.push(frontendRoutes.signIn(params.builder_name).path);
  };
  return (
    <section className="main-section search-screen d-flex justify-content-center">
      <div className="box">
        <div className="main-title">
          <h1>{searchCommunity.choose.label}</h1>
        </div>
        <span onClick={previousPath} className="link-back icn icn-back-arrow"></span>
        <div className="box-data d-flex flex-column">
          <Form>
            <Form.Group controlId="community">
              <Form.Control
                type="text"
                placeholder="Search by property name, address, or lot"
                name="search"
                onChange={e => searchItems(e.target.value)}
              />
            </Form.Group>
          </Form>
          {isLoading && <Loader />}
          <ul className="d-flex flex-column item-list">
            {searchInput.length > 1 ? (
              filterData.length !== 0 ? (
                filterData.map((data: any) => {
                  return (
                    <li className="item item-hide-image" key={data.id}>
                      <figure className="picture">
                        <img src={search_image} alt="Verify Identify" />
                      </figure>
                      <div className="item-data">
                        <span
                          onClick={() => getPropertyValue(data)}
                          className="icn icn-back-arrow"
                        ></span>
                        <h2>Fairfield-{data.id}</h2>
                        <small>{data.address}</small>
                        <span className="small">${data.price}</span>
                        <ul className="d-flex small">
                          <li>
                            <i className="icn icn-bad"></i>
                            {data.beds}Bd
                          </li>
                          <li>
                            <i className="icn icn-bath"></i>
                            {data.baths}Fba
                          </li>
                          <li>
                            <i className="icn icn-bath"></i>
                            {data.hBaths}Hba
                          </li>
                          <li>
                            <i className="icn icn-feet"></i>
                            {data.sqft}Sqft
                          </li>
                        </ul>
                      </div>
                    </li>
                  );
                })
              ) : (
                <span>No Result Found !!!</span>
              )
            ) : (
              propertyList &&
              propertyList.map((data: any) => {
                return (
                  <li className="item item-hide-image" key={data.id}>
                    <figure className="picture">
                      <img src={search_image} alt="Verify Identify" />
                    </figure>
                    <div className="item-data">
                      <span
                        onClick={() => getPropertyValue(data)}
                        className="icn icn-back-arrow"
                      ></span>
                      <h2>Fairfield-{data.id}</h2>
                      <small>{data.address}</small>
                      <span className="small">${data.price}</span>
                      <ul className="d-flex small">
                        <li>
                          <i className="icn icn-bad"></i>
                          {data.beds}Bd
                        </li>
                        <li>
                          <i className="icn icn-bath"></i>
                          {data.baths}Fba
                        </li>
                        <li>
                          <i className="icn icn-bath"></i>
                          {data.hBaths}Hba
                        </li>
                        <li>
                          <i className="icn icn-feet"></i>
                          {data.sqft}Sqft
                        </li>
                      </ul>
                    </div>
                  </li>
                );
              })
            )}
          </ul>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default SearchCommunity;
