import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { Link, useParams } from 'react-router-dom';

import community_image_1 from '../../../assets/images/community-image-1.jpg';
// import community_image_2 from '../../../assets/images/community-image-2.jpg'
import { useEffect } from 'react';
import { getBuilderId, static_token } from '../../../Constants/api';
import { frontendRoutes } from '../../../Constants/constants';

export const CommunityScreen = () => {
  let params = useParams<{ builder_name?: string }>();
  useEffect(() => {
    localStorage.setItem('builder_name', JSON.stringify(params.builder_name));
    static_token();
    getBuilderId(params);
  }, [params]);
  return (
    <section className="main-section community-screen d-flex  justify-content-center">
      <div className="box">
        <Header />
        <div className="box-data d-flex flex-column position-relative">
          <div className="mt-auto mb-auto">
            <figure className="text-center community-image">
              <img src={community_image_1} alt="Community" />
            </figure>
          </div>
          <Link
            to={frontendRoutes.chooseCommunity(params.builder_name).path}
            className="button w-100 mt-5"
          >
            Select a Community
          </Link>
        </div>
        <Footer />
      </div>
    </section>
  );
};
