import axios from 'axios';
import { FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { backendRoutes, frontendRoutes } from '../../../Constants/constants';
import Loader from '../../Loader/Loader';

const OtpVerification = () => {
	const history = useHistory();
	const previousPath = () => {
		history.goBack();
	}
	let params = useParams<{ builder_name?: string }>();
	const phoneNumber = useSelector((state: any) => state.phNumber.phNumber.phone)
	const [showAlert, setShowAlert] = useState(false);
	const [message, setMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isResend, setIsResend] = useState(false);

	const token = localStorage.getItem("message_token");

	const secret_token = localStorage.getItem("secure_token")

	const resend_otp = () => {
		axios({
			baseURL: backendRoutes.MESSAGING_BASE_URL,
			url: backendRoutes.get_otp,
			method: "post",
			headers: {
				"x-auth-token": token
			},
			data: {
				phone: phoneNumber
			}
		}).then((res) => {
			if (res.status === 201) {
				setIsResend(true);
				const secure_token2 = res.data.secret;
				localStorage.setItem("secure_token2", secure_token2);
			}
		}).catch((err) => {
			console.log(err);
		})
	}

	const otp_verify = (formData: any) => {
		setIsLoading(true);
		axios({
			baseURL: backendRoutes.MESSAGING_BASE_URL,
			url: backendRoutes.otp_verify,
			method: "POST",
			headers: {
				"x-auth-token": token
			},
			data: formData
		}).then((res) => {
			if (res.status === 201 && res.data.verified === true) {
				setIsLoading(false);
				history.push(frontendRoutes.textVerify(params.builder_name).path)
			}
			else {
				if (res.status === 201 && res.data.verified === false) {
					setIsLoading(false);
					const msg = "Otp is not verified, try again Or resend it";
					setShowAlert(true);
					setMessage(msg);
					setTimeout(() => {
						setShowAlert(false)
					}, 2000)
				}
			}
		}).catch((err) => {
			if (err.response.status === 403) {
				setIsLoading(false)
				const msg = err.response.data;
				setShowAlert(true);
				setMessage(msg);
				setTimeout(() => {
					setShowAlert(false)
				}, 2000)

			}
			else {
				setIsLoading(false)
				setShowAlert(true);
				setMessage("Something went Wrong or unexpected or Network Error !!!");
				setTimeout(() => {
					setShowAlert(false)
				}, 2000)
			}
		})
	}


	const getResendOtp = (e: any) => {
		e.preventDefault();
		resend_otp()
	}
	const secret_token2 = localStorage.getItem("secure_token2")
	const formik = useFormik({
		initialValues: {
			otp: ''
		},
		onSubmit: (values) => {
			const data = {
				secret: isResend ? secret_token2 : secret_token,
				code: values.otp
			}
			otp_verify(data)
		}
	})
	return (
		<section className="main-section otp-screen d-flex justify-content-center">
			<div className="box">
				<div className="main-title">
					<h1>Phone Verification</h1>
					<span><sup>*</sup>required</span>
				</div>
				<span onClick={previousPath} className="link-back icn icn-back-arrow"></span>
				<div className="box-data d-flex flex-column">
					<Form onSubmit={formik.handleSubmit} className="h-100 d-flex flex-column">
						<FormikProvider value={formik}>
							<Form.Group>
								<Form.Label>Enter the code sent to your phone.<sup>*</sup></Form.Label>
								<div className="input-box d-flex justify-content-between flex-column otp-code">
									<OtpInput
										value={formik.values.otp}
										onChange={(ot: any) => formik.setFieldValue('otp', ot)}
										numInputs={6}
										separator={<span> </span>}
										isInputNum={true}
										isInputSecure={false}
										inputStyle="form-control"
										containerStyle="w-100 justify-content-between"
										placeholder="000000"
									/>
									<span className="invalid-feedback d-block">
										{showAlert ? message : ""}
									</span>
								</div>
							</Form.Group>
						</FormikProvider>
						<span className="resend-link">Having trouble? <span className="a" onClick={getResendOtp}>Resend.</span></span>
						{isLoading && <Loader />}
						<div className="box-footer mt-auto">
							<button type="submit" className="button w-100">Next</button>
						</div>
					</Form>

				</div>
			</div>
		</section>
	)
}

export default OtpVerification
