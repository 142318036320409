import { useSelector } from 'react-redux';
import { selectBuilderSettings } from '../../redux/selectors/builders';
import defaultLogo from '../../assets/images/default-logo.svg';

const Header = () => {
  const builderSettings = useSelector(selectBuilderSettings);

  return (
    <header>
      <div className="brand-logo">
        <a href="https://nternow.com/" rel="noreferrer noopener" target="_blank">
          <img src={builderSettings.logo || defaultLogo} alt="header-logo" />
        </a>
      </div>
    </header>
  );
};

export default Header;
