import { useState } from 'react';
import axios from 'axios';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { useHistory,useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { backendRoutes, frontendRoutes } from '../../../Constants/constants';
import { setTimeout } from 'timers';
import { emailValidation, registerValidation } from '../../../Constants/validations';
import Loader from '../../Loader/Loader';
import { userDetail } from '../../../redux/action/action';
import { isToLogin, clearSetLogin } from "./../../../utils";

const EmailVerify = () => {
	const history = useHistory();
	const [editModeEmail, setEditModeEmail] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [message, setMessage] = useState('');
	const [showAlert, setShowAlert] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [emailFocused, setEmailFocused] = useState(false);
	const [passwordFocused, setPasswordFocused] = useState(false);
	const dispatch = useDispatch()
	let params = useParams<{builder_name?: string}>();

	const register = useSelector((state: any) => state.register.register)
	const propertyId = useSelector((state:any) => state.property.property.id);

	const token = localStorage.getItem("static_token")
	

	const togglePassword = () => {
		setShowPassword(showPassword ? false : true);
	}
	const editEmail = () => {
		setEditModeEmail(true);
	}

	const verify_email = (formData: any) => {
		setIsLoading(true)
		axios({
			baseURL: backendRoutes.MOBILE_BASE_URL,
			url: backendRoutes.auth_customer,
			method: "POST",
			headers: {
				"x-auth-token": token
			},
			data: formData
		}).then((res) => {
			setShowAlert(false)
			if (res.status === 201) {
				setIsLoading(false)
				const userToken = res.data.token
				localStorage.setItem("user_token", userToken);
				clearSetLogin();
				history.push(frontendRoutes.preferTour(params.builder_name).path)
			}
		}).catch((err) => {
			if (err && err.response && err.response.status === 403) {
				setIsLoading(false)
				const msg = err.response.data;
				setShowAlert(true);
				setMessage(msg);
			}
			else {
				setIsLoading(false)
				setShowAlert(true);
				setMessage("Something went wrong Or Network err !!!");
				setTimeout(() => {
					setShowAlert(false)
				}, 2000)
			}
		})
	}

	const formik = useFormik({
		initialValues: {
			email: register?.email ? register?.email : "",
			password: "",
			policy: ""
		},
		enableReinitialize: true,
		validationSchema: !isToLogin() ? registerValidation : emailValidation,
		onSubmit: (values) => {
			if (!isToLogin()) {
				const data = {
					firstName: register?.firstName,
					lastName: register?.lastName,
					street: register?.streetAddress,
					zipCode: register?.zipCode,
					phoneNumber: register?.phoneNumber,
					email: values.email,
					password: values.password,
					propertyId:propertyId,
					visitorStatusId: 154,
					visitorContactStatusId: 156
				}
				console.log(data);
				dispatch(userDetail(data,history,frontendRoutes.preferTour(params.builder_name).path));
				localStorage.removeItem('fields');
			}
			else {
				const data = {
					email: values.email,
					password: values.password
				}
				verify_email(data)
			}
		}
	})
	return (
		<section className="main-section varification-screen d-flex justify-content-center">
			<div className="box">
				{(!isToLogin()) ? (
					<div className="box-head">
						<h1><i className="icn icn-thumb"></i>Nice to Meet You!</h1>
						<h2>Let’s make this permanent:</h2>
						<span><sup>*</sup>required</span>
					</div>
				) : (
					<div className="box-head">
						<h1><i className="icn icn-thumb"></i>Nice to See You Again!</h1>
						<h2>Please Login Below</h2>
						<span><sup>*</sup>required</span>
					</div>
				)}
				<div className="box-data d-flex flex-column">
					<Form className="material-form d-flex flex-column h-100" onSubmit={formik.handleSubmit}>
						<FormikProvider value={formik}>
							{
								(editModeEmail === false && !isToLogin()) ? (
									<label className="edit-link">Hello, {formik.values.email} <i className="icn icn-edit" onClick={editEmail}></i></label>
								) : (
									<Form.Group className={(emailFocused === false && formik.values.email === '') ? "form-group" : "form-group input-focus"} controlId="email">
										<Form.Control
											type="text"
											name="email"
											onChange={formik.handleChange}
											value={formik.values.email}
											isInvalid={formik.touched.email && !!formik.errors.email}
											onFocus={() => setEmailFocused(true)}
											onBlur={(e) => { setEmailFocused(false); formik.handleBlur(e) }}
										/>
										<Form.Label>Email</Form.Label>
										<Form.Control.Feedback type="invalid">
											{formik.errors.email}
										</Form.Control.Feedback>
									</Form.Group>
								)
							}

							<Form.Group className={(passwordFocused === false && formik.values.email === '') ? "form-group" : "form-group input-focus"} controlId="password">
								<i className={showPassword ? "icn icn-eye active" : "icn icn-eye"} onClick={togglePassword}></i>
								<Form.Control
									type={showPassword ? "text" : "password"}
									name="password"
									onChange={formik.handleChange}
									value={formik.values.password}
									isInvalid={formik.touched.password && !!formik.errors.password}
									onFocus={() => setPasswordFocused(true)}
									onBlur={(e) => { setPasswordFocused(false); formik.handleBlur(e) }}
								/>
								<Form.Label>Password</Form.Label>
								<Form.Control.Feedback type="invalid">
									{formik.errors.password}
								</Form.Control.Feedback>
							</Form.Group>
							<span className="invalid-feedback d-block ">{showAlert ? message : " "}</span>

							<div className={!isToLogin() ? "" : "d-none"}>
								<div className="note">
									<small>NterNow would like to connect you with Lynne’s Tester agents by calling, texing, and/or emailing to provide you with the latest offers and incentives. NterNow and Lynnes’ Tester serve YOU and will never monetize your customer information. <sup>*</sup></small>
								</div>
								<Form.Group className={formik.errors.policy ? "form-group form-group-radio mt-0 is-invalid" : "form-group form-group-radio mt-0"}>
									<Form.Check
										type="radio"
										label="I have read and accept NterNow’s privacy policy."
										name="policy"
										id="radio-accept"
										onChange={formik.handleChange}
										isInvalid={formik.touched.policy && !!formik.errors.policy}
										value="accepted"
										onBlur={(e)=>{formik.handleBlur(e)}}
									/>
									<Form.Check
										type="radio"
										label="I have read and decline NterNow’s privacy policy."
										name="policy"
										id="radio-decline"
										onChange={formik.handleChange}
										isInvalid={formik.touched.policy && !!formik.errors.policy}
										value="decline"
										onBlur={(e)=>formik.handleBlur(e)}
									/>
									<span className="invalid-feedback">{formik.errors.policy ? "Required" : ""}</span>
								</Form.Group>
							</div>

							{isLoading && <Loader />}
							<div className="box-footer mt-auto">
								<button type="submit" className="button w-100">{!isToLogin() ? "Register" : "Login"}</button>
							</div>
						</FormikProvider>
					</Form>
				</div>
			</div>
		</section>
	)
}

export default EmailVerify
