import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { backendRoutes, checkOS, frontendRoutes } from '../../../../Constants/constants';
import Loader from '../../../Loader/Loader';

const LockScreen = () => {
  const userDetails = useSelector((state: any) => state.register.register);
  const property_details = useSelector((state: any) => state.property.property);
  const property_id = property_details && property_details.id;
  const user_token = localStorage.getItem('user_token');
  const [lockCode, setLockCode] = useState('');
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let params = useParams<{ builder_name?: string }>();

  const history = useHistory();
  const previousPath = () => {
    history.goBack();
  };

  useEffect(() => {
    const get_property_code = () => {
      setIsLoading(true);
      axios({
        baseURL: backendRoutes.MOBILE_BASE_URL,
        url: `properties/${property_id}/webflow-code`,
        method: 'GET',
        headers: {
          'x-auth-token': user_token,
        },
      })
        .then(res => {
          const lock_code = res.data.code;
          if (res.status === 200) {
            setIsLoading(false);
            setLockCode(lock_code);
            localStorage.setItem('lock_code', lock_code);
          }
        })
        .catch(err => {
          if (err && err.response && err.response.status === 403) {
            setIsLoading(false);
            const msg = err.response.data;
            setShowAlert(true);
            setMessage(msg);
          } else {
            setIsLoading(false);
            setShowAlert(true);
            setMessage('Something Went Wrong Or Network Error');
            setTimeout(() => {
              setShowAlert(false);
            }, 3000);
          }
        });
    };

    get_property_code();
  }, [property_id, user_token]);

  return (
    <section className="main-section success-screen tour-success-screen d-flex justify-content-center">
      <div className="box">
        <div className="success-title">
          <h1>SUCCESS!</h1>
          <span>
            Hi {userDetails?.firstName}! You are all set to tour: Fairfield{' '}
            {property_details && property_details.address}
          </span>
          {lockCode === '' ? (
            <div>
              <span onClick={previousPath} className="a">
                Cancel
              </span>
              <Link to={frontendRoutes.selectTourDate(params.builder_name).path} className="">
                Reschedule
              </Link>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="box-data d-flex flex-column text-center">
          <div className="my-auto">
            {showAlert === false ? <h2 className="h1">Your lock code is:</h2> : <h2>{message}</h2>}
            <label>{lockCode}</label>
            <span>
              Unable to unlock?{' '}
              <a href="tel:+844-531-1283" className="" target="_blank" rel="noreferrer">
                Help <i className="icn icn-phone"></i>
              </a>
            </span>
          </div>
          {isLoading && <Loader />}
          <div className="box-footer">
            <button onClick={checkOS} className="button w-100">
              Download NterNow
            </button>
            {/* onClick={checkOS} */}
            <p>
              To make the most of the NterNow experience, please download the mobile app. For more
              questions: <Link to="#">FAQ</Link>.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LockScreen;
