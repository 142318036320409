import { PHONE_NUMBER_FAIL, PHONE_NUMBER_REQUEST, PHONE_NUMBER_SUCCESS } from "../types/actionTypes";

export const phoneNumberReducer = (state={},action:any) => {
    switch (action.type) {
        case PHONE_NUMBER_REQUEST:
          return { ...state, loading: true };
        case PHONE_NUMBER_SUCCESS:
          return { loading: false, phNumber: action.payload };
        case PHONE_NUMBER_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;
      }
}
