import React from 'react'

import copyright from '../../assets/images/icn-logo.svg'

const Footer = () => {
    return (
        <footer>
        	<span>Powered By <img src={copyright} alt="Copyright Image" /></span>
        </footer>
    )
}

export default Footer
