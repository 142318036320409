import { FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { frontendRoutes } from '../../../Constants/constants';
import { userValidationSchema } from '../../../Constants/validations';
import { registerUser } from '../../../redux/action/action';
import Loader from '../../Loader/Loader';
import InputMask from 'react-input-mask'

const IsThisYou = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	let params = useParams<{ builder_name?: string }>();
	const [isLoading, setIsLoading] = useState(false)
	const [firstNameFocus, setFirstNameFocus] = useState(false)
	const [lastNameFocus, setLastNameFocus] = useState(false)
	const [emailFocus, setEmailFocus] = useState(false)
	const [addressFocus, setAddressFocus] = useState(false)
	const [zipcodeFocus, setZipcodeFocus] = useState(false)
	const [phNumberFocus, setPhNumberFocus] = useState(false)
	const isFields = () => {
		const fields = localStorage.getItem('fields');
		if (fields) {
			return true;
		}

		return false;
	}

	const fieldItem = isFields() ? JSON.parse(localStorage.getItem('fields') || '{}') : '';
	var firstname = fieldItem && fieldItem[Object.keys(fieldItem)[2]].value;
	var lastname = fieldItem && fieldItem[Object.keys(fieldItem)[1]].value;
	var postalcode = fieldItem && fieldItem[Object.keys(fieldItem)[9]].value;
	var street = fieldItem && fieldItem[Object.keys(fieldItem)[7]].value;
	var phNumber = fieldItem && fieldItem[Object.keys(fieldItem)[5]].value;

	const phoneNumber = useSelector((state: any) => state.phNumber.phNumber.phone)
	const goToPreviousPage = () => {
		history.goBack();
	}
	const formik = useFormik({
		initialValues: (isFields()) ?
			({
				firstName: firstname || '',
				lastName: lastname || '',
				streetAddress: street || '',
				zipCode: postalcode || '',
				phoneNumber: phNumber ? phNumber : phoneNumber,
				email: '',
				agent: ''
			})
			:
			({
				firstName: '',
				lastName: '',
				streetAddress: '',
				zipCode: '',
				phoneNumber: '',
				email: '',
				agent: ''
			}),
		validationSchema: userValidationSchema,
		onSubmit: values => {
			const data = {
				firstName: values.firstName,
				lastName: values.lastName,
				streetAddress: values.streetAddress,
				zipCode: values.zipCode,
				phoneNumber: values.phoneNumber.replace(/\D/g, ''),
				email: values.email,
				agent: values.agent
			}
			dispatch(registerUser(data))
			setIsLoading(true)
			setTimeout(() => {
				setIsLoading(false)
				history.push(frontendRoutes.emailVerify(params.builder_name).path)
			}, 1000)
		},
	});
	return (
		<section className="main-section d-flex justify-content-center">
			<div className="box">
				<div className="main-title">
					<h1>Is this you?</h1>
					<span><sup>*</sup>required</span>
				</div>
				{/* focus?"form-group input-focus":"form-group" */}
				<span className="link-back" onClick={goToPreviousPage}>Cancel</span>
				<div className="box-data d-flex flex-column">
					<Form className="material-form h-100 d-flex flex-column" noValidate onSubmit={formik.handleSubmit}>
						<FormikProvider value={formik}>
							<Form.Group className={(firstNameFocus === false && formik.values.firstName === '') ? "form-group" : "form-group input-focus"} controlId="first-name">
								<Form.Control
									type="text"
									name="firstName"
									onChange={formik.handleChange}
									value={formik.values.firstName}
									isInvalid={formik.touched.firstName && !!formik.errors.firstName}
									onFocus={() => setFirstNameFocus(true)}
									onBlur={(e) => { setFirstNameFocus(false); formik.handleBlur(e) }}
								/>
								<Form.Label>First name<sup>*</sup></Form.Label>
								<Form.Control.Feedback type="invalid">
									{formik.errors.firstName}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className={(lastNameFocus === false && formik.values.lastName === '') ? "form-group" : "form-group input-focus"} controlId="last-name">
								<Form.Control
									type="text"
									name="lastName"
									onChange={formik.handleChange}
									value={formik.values.lastName}
									isInvalid={formik.touched.lastName && !!formik.errors.lastName}
									onFocus={() => setLastNameFocus(true)}
									onBlur={(e) => { setLastNameFocus(false); formik.handleBlur(e) }}
								/>
								<Form.Label>Last name<sup>*</sup></Form.Label>
								<Form.Control.Feedback type="invalid">
									{formik.errors.lastName}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className={(addressFocus === false && formik.values.streetAddress === '') ? "form-group" : "form-group input-focus"} controlId="address">
								<Form.Control
									type="text"
									name="streetAddress"
									onChange={formik.handleChange}
									value={formik.values.streetAddress}
									isInvalid={formik.touched.streetAddress && !!formik.errors.streetAddress}
									onFocus={() => setAddressFocus(true)}
									onBlur={(e) => { setAddressFocus(false); formik.handleBlur(e) }}
								/>
								<Form.Label>Street address<sup>*</sup></Form.Label>
								<Form.Control.Feedback type="invalid">
									{formik.errors.streetAddress}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className={(zipcodeFocus === false && formik.values.zipCode === '') ? "form-group" : "form-group input-focus"} controlId="zip-code">
								<Form.Control
									type="text"
									name="zipCode"
									onChange={formik.handleChange}
									value={formik.values.zipCode}
									isInvalid={formik.touched.zipCode && !!formik.errors.zipCode}
									onFocus={() => setZipcodeFocus(true)}
									onBlur={(e) => { setZipcodeFocus(true); formik.handleBlur(e) }}
								/>
								<Form.Label>Zipcode<sup>*</sup></Form.Label>
								<Form.Control.Feedback type="invalid">
									{formik.errors.zipCode}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className={(phNumberFocus === false && formik.values.phoneNumber === '') ? "form-group" : "form-group input-focus"} controlId="phone-number">
								<InputMask
									mask="999-999-9999"
									onChange={formik.handleChange}
									onFocus={() => setPhNumberFocus(true)}
									onBlur={(e) => { setPhNumberFocus(false); formik.handleBlur(e) }}
									value={formik.values.phoneNumber}
								>{() => (
									<Form.Control
										type="text"
										name="phoneNumber"
										isInvalid={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
									/>
								)}</InputMask>
								<Form.Label>Phone Number<sup>*</sup></Form.Label>
								<Form.Control.Feedback type="invalid">
									{formik.errors.phoneNumber}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className={(emailFocus === false && formik.values.email === '') ? "form-group" : "form-group input-focus"} controlId="email">
								<Form.Control
									type="text"
									name="email"
									onChange={formik.handleChange}
									value={formik.values.email}
									isInvalid={formik.touched.email && !!formik.errors.email}
									onFocus={() => setEmailFocus(true)}
									onBlur={(e) => { setEmailFocus(false); formik.handleBlur(e) }}
								/>
								<Form.Label>Email<sup>*</sup></Form.Label>
								<Form.Control.Feedback type="invalid">
									{formik.errors.email}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className={formik.errors.agent ? "form-group form-group-radio is-invalid" : "form-group form-group-radio"}>
								<Form.Label>Please select one.<sup>*</sup></Form.Label>
								<Form.Check
									type="radio"
									label="I am an agent"
									name="agent"
									id="radio-agent"
									value="agent"
									onChange={formik.handleChange}
									isInvalid={formik.touched.agent && !!formik.errors.agent}
									onBlur={(e) => formik.handleBlur(e)}
								/>
								<Form.Check
									type="radio"
									label="I am NOT an agent"
									name="agent"
									id="radio-agent-not"
									value="not-agent"
									onChange={formik.handleChange}
									isInvalid={!!formik.errors.agent}
									onBlur={(e) => formik.handleBlur(e)}
								/>
								<span className="invalid-feedback">{formik.errors.agent ? "Required" : ""}</span>
							</Form.Group>
							{isLoading && <Loader />}
							<div className="box-footer mt-auto">
								<button type="submit" className="button w-100">Yes It's Me</button>
								<span className="policy-text"><i className="icn icn-lock"></i>Your information is protected by our <a href="https://nternow.com/" rel="noreferrer" target="_blank">privay policy</a>.</span>
							</div>
						</FormikProvider>
					</Form>
				</div>
			</div>
		</section>
	)
}

export default IsThisYou