import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import { Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { FormikProvider, useFormik } from 'formik';
import { frontendRoutes } from '../../../Constants/constants';
import { chooseValidation } from '../../../Constants/validations';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { choose_community, search_community } from '../../../redux/action/action';
import Loader from '../../Loader/Loader';

const ChooseCommunity = () => {
  const history = useHistory();
  const previousPath = () => {
    history.goBack();
  };
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  let params = useParams<{ builder_name?: string }>();

  useEffect(() => {
    dispatch(choose_community());
  }, [dispatch]);

  const community = useSelector((state: any) => state.community.community);

  const options =
    community &&
    community.map((a: any) => {
      return { label: a.community.name, value: a.id };
    });

  const formik = useFormik({
    initialValues: {
      choose: null,
      privacyPolicy: false,
      covidPolicy: false,
    },
    validationSchema: chooseValidation,
    onSubmit: values => {
      const data = {
        choose: values.choose,
      };
      setIsLoading(true);
      dispatch(search_community(data));
      setTimeout(() => {
        setIsLoading(false);
        history.push(frontendRoutes.searchCommunity(params.builder_name).path);
      }, 1000);
    },
  });

  return (
    <section className="main-section community-screen d-flex justify-content-center">
      <div className="box">
        <Header />
        <span onClick={previousPath} className="link-back icn icn-back-arrow"></span>
        <div className="box-data d-flex flex-column position-relative">
          <span className="required-text">
            <sup>*</sup>required
          </span>
          <Form onSubmit={formik.handleSubmit} className="d-flex flex-column h-100">
            <FormikProvider value={formik}>
              <Form.Group controlId="community">
                <Form.Label>
                  What community would you like to tour?<sup>*</sup>
                </Form.Label>
                <Select
                  options={options}
                  onChange={option => formik.setFieldValue('choose', option)}
                  value={formik.values.choose}
                  classNamePrefix="netrnow-select"
                  placeholder="Please select a community"
                />
                {formik.errors.choose ? (
                  <span className="invalid-feedback d-block">{formik.errors.choose}</span>
                ) : (
                  ''
                )}
              </Form.Group>
              <div className="box-footer mt-auto">
                <Form.Group
                  className={
                    (formik.errors.covidPolicy && formik.errors.privacyPolicy) ||
                    formik.errors.privacyPolicy ||
                    formik.errors.covidPolicy
                      ? 'form-group form-group-checkbox mt-0 is-invalid'
                      : 'form-group form-group-checkbox mt-0'
                  }
                >
                  <Form.Check
                    type="checkbox"
                    label={
                      <small>
                        I have read and accept NterNow’s{' '}
                        <a href="https://nternow.com/" rel="noreferrer" target="_blank">
                          Privacy Policy
                        </a>{' '}
                        and{' '}
                        <a href="https://nternow.com/" target="_blank" rel="noreferrer">
                          Terms of service
                        </a>
                        .
                      </small>
                    }
                    id="privacyPolicy"
                    checked={formik.values.privacyPolicy}
                    isInvalid={!!formik.errors.privacyPolicy}
                    onChange={() =>
                      formik.setFieldValue('privacyPolicy', !formik.values.privacyPolicy)
                    }
                  />
                  <Form.Check
                    type="checkbox"
                    label={
                      <small>
                        I have read and accept NterNow’s{' '}
                        <a href="https://nternow.com/" target="_blank" rel="noreferrer">
                          COVID-19 disclosure
                        </a>
                        .
                      </small>
                    }
                    id="covidPolicy"
                    checked={formik.values.covidPolicy}
                    isInvalid={!!formik.errors.covidPolicy}
                    onChange={() => formik.setFieldValue('covidPolicy', !formik.values.covidPolicy)}
                  />
                  <span className="invalid-feedback">
                    {(formik.errors.covidPolicy && formik.errors.privacyPolicy) ||
                    formik.errors.privacyPolicy ||
                    formik.errors.covidPolicy
                      ? 'You must accept both options to continue'
                      : ''}
                  </span>
                </Form.Group>
                {isLoading && <Loader />}
                <button type="submit" className="button w-100">
                  Continue
                </button>
              </div>
            </FormikProvider>
          </Form>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default ChooseCommunity;
