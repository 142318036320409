import { combineReducers } from 'redux';

import { communityReducer } from './communityReducer';
import { searchCommunityReducer } from './searchCommunityReducer';
import { tourDateReducer } from './tourDateReducer';
import { getPropertyReducer } from './getPropertyReducer';
import { phoneNumberReducer } from './phoneNumberReducer';
import { registerUserReducer } from './registerUserReducer';
import UserDetailsReducer from './userReducer';
import personaDataReducer from './personaDataReducer';
import builderReducer from './builderReducer';

const reducer = combineReducers({
  register: registerUserReducer,
  user: UserDetailsReducer,
  community: communityReducer,
  searchCommunity: searchCommunityReducer,
  property: getPropertyReducer,
  tour: tourDateReducer,
  phNumber: phoneNumberReducer,
  fields: personaDataReducer,
  builderSettings: builderReducer,
});

export const rootReducer = (state: any, action: any) => {
  return reducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
