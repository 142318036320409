import Footer from '../../Footer/Footer';
import { useHistory, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import {
  convertTime12to24,
  currentDate,
  defineds,
  now,
  options,
} from '../../../Constants/constants';
import { frontendRoutes } from '../../../Constants/constants';
import Select from 'react-select';
import { FormikProvider, useFormik } from 'formik';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from 'react';
import { tourDateValidation } from '../../../Constants/validations';
import { useDispatch, useSelector } from 'react-redux';
import { tourDate } from '../../../redux/action/action';
import Loader from '../../Loader/Loader';
import { selectBuilderSettings } from '../../../redux/selectors/builders';
import defaultLogo from '../../../assets/images/default-logo.svg';

const SelectTourDate = () => {
  const history = useHistory();
  let params = useParams<{ builder_name?: string }>();
  const previousPath = () => {
    history.goBack();
  };
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  // const toggleButton = () => {
  //   setIsOpen(!isOpen);
  // };
  // const [dateState, setDateState] = useState(new Date());
  // const [today, setToday] = useState(new Date());
  // const [tomorrow, setTomorrow] = useState(new Date());
  // const [in2Days, setIn2Days] = useState(new Date());
  const [startDate, setStartDate] = useState();
  // const [label, setLabel] = useState(' ');

  const builderSettings = useSelector(selectBuilderSettings);

  // const handleToday = () => {
  //   setDateState(defineds.startOfToday);
  //   setLabel('Today');
  //   const startDate = dateState.toLocaleDateString('en-GU', {
  //     month: 'long',
  //     day: '2-digit',
  //     year: 'numeric',
  //   });
  //   const finalDate = label !== ' ' ? label + ' ' + startDate : startDate;
  //   formik.setFieldValue('showDate', finalDate);
  // };

  // const handleTomorrow = () => {
  //   setDateState(defineds.startOfTomorrow);
  //   setLabel('Tomorrow');
  //   const startDate = dateState.toLocaleDateString('en-GU', {
  //     month: 'long',
  //     day: '2-digit',
  //     year: 'numeric',
  //   });
  //   const finalDate = label !== ' ' ? label + ' ' + startDate : startDate;
  //   formik.setFieldValue('showDate', finalDate);
  // };

  // const handle2Day = () => {
  //   setDateState(defineds.startOfLastTwoDay);
  //   setLabel(' ');
  //   const startDate = dateState.toLocaleDateString('en-GU', {
  //     month: 'long',
  //     day: '2-digit',
  //     year: 'numeric',
  //   });
  //   const finalDate = label !== ' ' ? label + ' ' + startDate : startDate;
  //   formik.setFieldValue('showDate', finalDate);
  // };

  const handleChange = (value: any) => {
    setStartDate(value)
    // if (value === defineds.startOfToday) {
    //   setLabel('Today');
    // } else if (value === defineds.startOfTomorrow) {
    //   setLabel('Tomorrow');
    // } else {
    //   setLabel(' ');
    // }
    // const finalDate = label !== ' ' ? label + ' ' + startDate : startDate;
    formik.setFieldValue('showDate', value.toLocaleDateString('en-GU', {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
    }));
  };

  // const closeDate = () => {
  //   setIsOpen(false);
  // };

  // const resetDate = () => {
  //   setIsOpen(false);
  //   formik.setFieldValue('showDate', '');
  // };
  const formik = useFormik({
    initialValues: {
      showDate: '',
      radioTime: '',
      customTime: '',
      choose: { label: 'AM', value: 'AM' },
    },
    enableReinitialize: true,
    validationSchema: tourDateValidation,
    onSubmit: values => {
      const nowDate = 'Today ' + currentDate;
      if (
        values.radioTime === 'now' &&
        (values.showDate === currentDate || values.showDate === nowDate)
      ) {
        setIsLoading(true);
        const data = {
          showDate: values.showDate === currentDate ? nowDate : values.showDate,
          radioTime: values.radioTime,
          customTime: new Date().toLocaleTimeString('en-GU', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }),
          choose: values.choose,
        };
        dispatch(tourDate(data));
        setTimeout(() => {
          setIsLoading(false);
          history.push(frontendRoutes.shareLocation(params.builder_name).path);
        }, 1000);
      } else {
        const time =
          values.radioTime === 'custom'
            ? values.customTime + ' ' + values.choose.value
            : new Date().toLocaleTimeString('en-GU', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            });

        const t = convertTime12to24(time);
        const n = new Date(now);
        const dt = new Date(values.showDate + ', ' + t);
        const nowDate = 'Today ' + currentDate;
        const tomorrow = defineds.startOfTomorrow.toLocaleDateString('en-GU', {
          month: 'long',
          day: '2-digit',
          year: 'numeric',
        });
        const tDate = 'Tomorrow ' + tomorrow;

        if (dt.getTime() > n.getTime()) {
          const data = {
            showDate:
              values.showDate === currentDate
                ? nowDate
                : values.showDate === tomorrow
                  ? tDate
                  : values.showDate,
            radioTime: values.radioTime,
            customTime: values.radioTime === 'custom' ? values.customTime : time,
            choose: values.radioTime === 'custom' ? values.choose : ' ',
          };
          setIsLoading(true);
          dispatch(tourDate(data));
          setTimeout(() => {
            setIsLoading(false);
            history.push(frontendRoutes.ConfirmTourScreen(params.builder_name).path);
          }, 1000);
        } else {
          if (values.customTime === '') {
            const msg = 'Required';
            setShowAlert(true);
            setMessage(msg);
            setTimeout(() => {
              setShowAlert(false);
            }, 2000);
          } else {
            const msg = 'Time must be greater than current time';
            setShowAlert(true);
            setMessage(msg);
            setTimeout(() => {
              setShowAlert(false);
            }, 2000);
          }
        }
      }
    },
  });

  return (
    <section className="main-section tour-screen d-flex justify-content-center">
      <div className="box">
        <div className="main-title">
          <figure>
            <img src={builderSettings.logo || defaultLogo} alt="Fairfield" />
          </figure>
          <span className="text-blue">
            <sup>*</sup>required
          </span>
        </div>
        <span onClick={previousPath} className="link-back icn icn-back-arrow"></span>

        <div className="box-data d-flex flex-column">
          <Form onSubmit={formik.handleSubmit} className="d-flex flex-column h-100">
            <FormikProvider value={formik}>
              <Form.Group controlId="" className="form-group form-date">
                <Form.Label>
                  What day would you like to tour?<sup>*</sup>
                </Form.Label>
                <DatePicker selected={startDate} onChange={handleChange}
                />
                {/* <div className="position-relative">
                  <Form.Control
                    type="text"
                    value={formik.values.showDate}
                    placeholder="Select Your Desired Day"
                    name="showDate"
                    isInvalid={!!formik.errors.showDate}
                    onFocus={toggleButton}
                    disabled
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.showDate}
                  </Form.Control.Feedback>
                  <i className="icn icn-calander" onClick={toggleButton}></i>
                  {isOpen ? (
                    <div className="custom-dropdown">
                      <div className="rdrDefinedRangesWrapper">
                        <div className="rdrStaticRanges">
                          <button
                            type="button"
                            className="rdrStaticRange rdrStaticRangeSelected"
                            onClick={handleToday}
                          >
                            Today
                          </button>
                          <button type="button" className="rdrStaticRange" onClick={handleTomorrow}>
                            Tomorrow
                          </button>
                          <button type="button" className="rdrStaticRange" onClick={handle2Day}>
                            In 2 Days
                          </button>
                        </div>
                      </div>
                      <span>Custom</span>
                      <Calendar
                        value={dateState}
                        onClickDay={handleChange}
                        minDate={new Date()}
                        next2Label={null}
                        prev2Label={null}
                        formatShortWeekday={(locale, date) =>
                          ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][date.getDay()]
                        }
                      />
                      <div className="date-btn d-flex justify-content-between">
                        <button className="a" onClick={resetDate}>
                          Cancel
                        </button>
                        <button className="btn-apply" onClick={closeDate}>
                          Apply
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div> */}
              </Form.Group>
              <Form.Group
                controlId=""
                className={
                  formik.errors.radioTime
                    ? 'form-group form-custom mb-0 is-invalid'
                    : 'form-group form-custom mb-0'
                }
              >
                <Form.Label>
                  What time would you like to tour?<sup>*</sup>
                </Form.Label>
                <div className="d-flex">
                  <Form.Check
                    type="radio"
                    className="w-auto"
                    label="NOW"
                    name="radioTime"
                    id="radio-now"
                    onChange={formik.handleChange}
                    value="now"
                    isInvalid={formik.touched && !!formik.errors.radioTime}
                    onBlur={e => formik.handleBlur(e)}
                  />
                  <i className="icn icn-instant"></i>
                </div>
                <div className="pb-0">
                  <div className="d-flex pb-0 justify-content-between custom-option align-items-center">
                    <Form.Check
                      type="radio"
                      className="mb-0"
                      label="Custom Time"
                      name="radioTime"
                      id="radio-custom"
                      value="custom"
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.radioTime && !!formik.errors.radioTime}
                      onBlur={e => {
                        formik.handleBlur(e);
                      }}
                    />
                    {formik.values.radioTime === 'custom' ? (
                      <div className="d-flex w-100 margin-sm">
                        <Form.Group controlId="form-time">
                          <TimePicker
                            value={formik.values.customTime}
                            disableClock={true}
                            format="hh:mm"
                            clearIcon={null}
                            hourPlaceholder="00"
                            minutePlaceholder="00"
                            onChange={time => formik.setFieldValue('customTime', time)}
                          />
                        </Form.Group>
                        <Form.Group controlId="form-mode">
                          <Select
                            options={options}
                            onChange={option => formik.setFieldValue('choose', option)}
                            value={formik.values.choose}
                            classNamePrefix="netrnow-select"
                          />
                        </Form.Group>
                      </div>
                    ) : null}
                  </div>
                  <span className="invalid-feedback d-block">{showAlert ? message : ''}</span>
                </div>
                <span className="invalid-feedback d-block">
                  {formik.errors.radioTime ? 'Required' : ''}
                </span>
              </Form.Group>
            </FormikProvider>
            {isLoading && <Loader />}
            <div className="box-footer mt-auto">
              <button type="submit" className="button w-100">
                Schedule
              </button>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default SelectTourDate;
