import {
  BUILDER_SETTINGS_FAIL,
  BUILDER_SETTINGS_REQUEST,
  BUILDER_SETTINGS_SUCCESS,
} from '../types/actionTypes';
import { BuilderSettingsState } from '../types/builders';

const initialState: BuilderSettingsState = {
  loading: false,
  settings: {
    builderName: '',
    primary_color: '',
    secondary_color: '',
    country: '',
    logo: '',
  },
  error: null,
};

export default function builderReducer(state = initialState, action: any) {
  switch (action.type) {
    case BUILDER_SETTINGS_REQUEST:
      return { ...state, loading: true };
    case BUILDER_SETTINGS_SUCCESS:
      return { loading: false, settings: action.payload };
    case BUILDER_SETTINGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
