import Persona from 'persona';
import { useHistory, useParams } from 'react-router';
import { frontendRoutes } from '../../../Constants/constants';

const TextVerify = () => {
    const history = useHistory();
    let params = useParams<{ builder_name?: string }>();

    return (
        <section className="main-section d-flex justify-content-center">
            <div className="box">
                <div className="box-data d-flex flex-column">
                    <Persona.Inquiry
                        templateId='tmpl_XNZZiE9JtSFc4Zys7njU5AgD'
                        environment='production'
                        onReady={() => {
                            console.log("ready");
                        }}
                        onLoad={() => { console.log('Loaded inline') }}
                        onComplete={({ inquiryId, status, fields }) => {
                            console.log(`Completed inquiry ${inquiryId} with status ${status}`);
                            localStorage.setItem("fields", JSON.stringify(fields));
                            history.push(frontendRoutes.isThisYou(params.builder_name).path);
                        }}
                    />
                </div>
            </div>
        </section>
    )
}

export default TextVerify
// tmpl_XNZZiE9JtSFc4Zys7njU5AgD
// environment='production'
// console.log(`Completed inquiry ${inquiryId} with status ${status}`);