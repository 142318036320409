import {
  SEARCH_COMMUNITY_FAIL,
  SEARCH_COMMUNITY_REQUEST,
  SEARCH_COMMUNITY_SUCCESS,
} from "../types/actionTypes";

export const searchCommunityReducer = (state = {}, action: any) => {
  switch (action.type) {
    case SEARCH_COMMUNITY_REQUEST:
      return { ...state, loading: true };
    case SEARCH_COMMUNITY_SUCCESS:
      return { loading: false, search: action.payload };
    case SEARCH_COMMUNITY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
