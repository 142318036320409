import jwt_decode from "jwt-decode";
import { USER_TOKEN_KEY, MESSAGING_TOKEN_KEY } from "../Constants/constants";

interface tokenPayload {
  customerId: number;
  username: string;
  iat: number;
  exp: number;
}

export const setToLogin = () => {
  localStorage.setItem("SET_TO_LOGIN", "1");
}

export const isToLogin = () => {
   const value = localStorage.getItem("SET_TO_LOGIN");
   return value === "1";
}

export const clearSetLogin = () => {
  localStorage.removeItem("SET_TO_LOGIN");
}

export const isUserTokenExpired = (token: string) => {
  let decodedToken: tokenPayload = jwt_decode(token);
  let currentDate = new Date();
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    return true;
  }

  return false;
}

export const isUserToken = () => {
  const token = localStorage.getItem(USER_TOKEN_KEY);

  if (token) {
    let decodedToken: tokenPayload = jwt_decode(token);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      return false;
    }

    return true;
  }

  return false;
};

export const isMessagingToken = () => {
  if (localStorage.getItem(MESSAGING_TOKEN_KEY)) {
    return true;
  }

  return false;
};
