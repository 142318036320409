import axios from 'axios';
import { backendRoutes } from '../../Constants/constants';
import {
  BUILDER_SETTINGS_FAIL,
  BUILDER_SETTINGS_REQUEST,
  BUILDER_SETTINGS_SUCCESS,
  CHOOSE_COMMUNITY_FAIL,
  CHOOSE_COMMUNITY_REQUEST,
  CHOOSE_COMMUNITY_SUCCESS,
  GET_PROPERTY_FAIL,
  GET_PROPERTY_REQUEST,
  GET_PROPERTY_SUCCESS,
  PHONE_NUMBER_FAIL,
  PHONE_NUMBER_REQUEST,
  PHONE_NUMBER_SUCCESS,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  SEARCH_COMMUNITY_FAIL,
  SEARCH_COMMUNITY_REQUEST,
  SEARCH_COMMUNITY_SUCCESS,
  TOUR_DATE_FAIL,
  TOUR_DATE_REQUEST,
  TOUR_DATE_SUCCESS,
  USER_DETAIL_FAIL,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
} from '../types/actionTypes';

export const registerUser = (formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: REGISTER_USER_REQUEST,
    });
    dispatch({
      type: REGISTER_USER_SUCCESS,
      payload: formData,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAIL_FAIL,
      payload: error,
    });
  }
};
export const userDetail = (formData: any, history: any, path: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_DETAIL_REQUEST,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('static_token'),
      },
    };
    const { data } = await axios.post(
      backendRoutes.MOBILE_BASE_URL + backendRoutes.customers,
      formData,
      config,
    );
    localStorage.setItem('user_token', data.token);
    dispatch({
      type: USER_DETAIL_SUCCESS,
      payload: localStorage.setItem('user_token', data.token),
    });
    history.push(path);
  } catch (error) {
    dispatch({
      type: USER_DETAIL_FAIL,
      payload: error,
    });
  }
};

export const personaData = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: PHONE_NUMBER_REQUEST,
    });

    dispatch({
      type: PHONE_NUMBER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PHONE_NUMBER_FAIL,
      payload: error,
    });
  }
};
export const choose_community = () => async (dispatch: any) => {
  const id = localStorage.getItem('builder_id');
  try {
    dispatch({
      type: CHOOSE_COMMUNITY_REQUEST,
    });
    const config = {
      params: { isAvailable: true },
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('static_token'),
      },
    };
    const { data } = await axios.get(
      `${backendRoutes.MOBILE_BASE_URL}properties/communities/${id}`,
      config,
    );
    const arr = data.map((community: any, id: any) => {
      return { community, id };
    });
    dispatch({
      type: CHOOSE_COMMUNITY_SUCCESS,
      payload: arr,
    });
  } catch (error) {
    dispatch({
      type: CHOOSE_COMMUNITY_FAIL,
      payload: error,
    });
  }
};

export const search_community = (formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SEARCH_COMMUNITY_REQUEST,
    });

    dispatch({
      type: SEARCH_COMMUNITY_SUCCESS,
      payload: formData,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_COMMUNITY_FAIL,
      payload: error,
    });
  }
};

export const get_property_details = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: GET_PROPERTY_REQUEST,
    });
    dispatch({
      type: GET_PROPERTY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_PROPERTY_FAIL,
      payload: error,
    });
  }
};

export const tourDate = (formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: TOUR_DATE_REQUEST,
    });

    dispatch({
      type: TOUR_DATE_SUCCESS,
      payload: formData,
    });
  } catch (error) {
    dispatch({
      type: TOUR_DATE_FAIL,
      payload: error,
    });
  }
};

export const getPhoneNumber = (formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: PHONE_NUMBER_REQUEST,
    });

    dispatch({
      type: PHONE_NUMBER_SUCCESS,
      payload: formData,
    });
  } catch (error) {
    dispatch({
      type: PHONE_NUMBER_FAIL,
      payload: error,
    });
  }
};

export const getBuilderSettings = () => async (dispatch: any) => {
  const id = localStorage.getItem('builder_id');
  try {
    dispatch({
      type: BUILDER_SETTINGS_REQUEST,
    });

    const { data } = await axios.get(`${backendRoutes.MOBILE_BASE_URL}builders/settings/${id}`);

    dispatch({
      type: BUILDER_SETTINGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BUILDER_SETTINGS_FAIL,
      payload: error,
    });
  }
};
