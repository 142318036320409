import Footer from '../../Footer/Footer';
import icn_identify from '../../../assets/images/icn-identify.svg';
import icn_code from '../../../assets/images/icn-code.svg';
import { Link, useHistory, useParams } from 'react-router-dom';
import { frontendRoutes } from '../../../Constants/constants';

const HowitWork = () => {
  const history = useHistory();
  const previousPath = () => {
    history.goBack();
  };
  let params = useParams<{ builder_name?: string }>();

  return (
    <section className="main-section work-screen d-flex justify-content-center">
      <div className="box">
        <div className="main-title">
          <h1>How It Works:</h1>
        </div>
        <span onClick={previousPath} className="link-back icn icn-back-arrow"></span>
        <div className="box-data d-flex flex-column">
          <ul className="work-item">
            <li className="d-flex align-items-center">
              <figure>
                <img src={icn_identify} alt="Verify Identify" />
              </figure>
              <div className="work-text">
                <p>
                  Verify Identity, set up your account, select a property, and schedule your tour.
                  Once on-site: enter the property ID.
                </p>
              </div>
            </li>
            <li className="d-flex align-items-center">
              <figure>
                <img src={icn_code} alt="Code" />
              </figure>
              <div className="work-text">
                <p>We give you the code to unlock self tours on your time!</p>
              </div>
            </li>
          </ul>
          <div className="box-footer mt-auto">
            <Link
              to={frontendRoutes.phoneVerify(params.builder_name).path}
              className="button w-100"
            >
              Let's Get Started
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default HowitWork;
